<template>
  <div class="dashboard-panel" :class="{ 'is-on-fire': onFire }">
    <!-- BASE -->
    <div class="dashboard-panel-base">
      <!-- BLACK -->
      <div class="dashboard-panel-base-black">
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
          <!-- FLAME -->
          <div v-if="onFire" class="dashboard-panel-flame-wrapper">
            <DashboardPanelFlame class="dashboard-panel-flame" />
          </div>
        </transition>

        <!-- BLACK TEXT -->
        <div class="dashboard-panel-base-black-text">
          <!-- NAME -->
          <span class="name">
            {{ name }}
          </span>

          <!-- VIEWERS -->
          <ICountUp v-if="name === 'Viewers'" :delay="0" :endVal="text" class="text text-primary" />

          <!-- LATEST (MARQUEE) -->
          <div v-else-if="name === 'Latest'" class="dynamic-marquee-container">
            <DynamicMarquee
              direction="row"
              :hoverPause="true"
              :repeat="true"
              :repeatMargin="10"
              :reverse="true"
              :speed="{ type: 'pps', number: 15 }"
            >
              <span class="text text-marquee">{{ text }}</span>
            </DynamicMarquee>
          </div>

          <!-- IF TEXT -->
          <span
            v-else-if="name === 'Followers' || name === 'Subs'"
            :key="text"
            class="text animated heartBeat"
          >
            {{ text }}
          </span>

          <!-- TEXT ADDITION -->
          <!-- <span v-if="textAddition" class="text-addition ml-1" v-text="'/ ' + textAddition"> </span> -->
        </div>

        <!-- SHINE -->
        <div class="dashboard-panel-base-black-shine">
          <!-- SHINE LINE -->
          <div class="dashboard-panel-base-black-shine-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ICountUp from "vue-countup-v2";
  import DynamicMarquee from "vue-dynamic-marquee";
  import DashboardPanelFlame from "./DashboardPanelFlame.vue";

  export default {
    components: {
      DashboardPanelFlame,
      DynamicMarquee,
      ICountUp,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      onFire: {
        type: Boolean,
        default: false,
      },
      text: {
        type: [String, Number],
        required: false,
      },
      textAddition: {
        type: [String, Number],
        required: false,
      },
    },
    computed: {
      isMarquee() {
        return typeof this.text === "string";
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dashboard-panel {
    position: relative;
    width: 132px;
    height: 66px;
    margin: 0 8px; // 10px
  }

  .dashboard-panel-base,
  .dashboard-panel-base-black,
  .dashboard-panel-base-black-text,
  .dashboard-panel-base-black-shine,
  .dashboard-panel-base-black-shine-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }

  .dashboard-panel-base {
    background: url("./assets/img/dashboard-panel-bg@2x.png") no-repeat center center;
    // background-size: calc(264px / 2);
    background-size: calc(264px / 2);
    border-radius: 8px;

    .dashboard-panel-base-black {
      margin: 3px;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      background: url("./assets/img/dashboard-panel-black@2x.png") no-repeat center center;
      background-size: calc(264px / 2);

      .dashboard-panel-base-black-text {
        margin-top: rem-calc(4); // 6px
        text-align: center;
        // border: 1px dashed yellow;
        overflow: hidden; // for text number bounces

        .name {
          display: block;
          font-family: "Front Page Neue";
          font-size: rem-calc(20); // 18
          font-weight: normal;
          text-transform: uppercase;
          color: lighten(#3c3e4a, 10%);
        }

        .text,
        .text-addition {
          display: inline-block;
          // max-width: 83%;
          max-width: calc(100% - 10px);
          margin: -3px auto 0 auto;
          font-family: "Avenir Next Condensed";
          font-size: rem-calc(22);
          // font-weight: bold;
          font-weight: 600;
          line-height: 1;
          color: $primary;
          // text-shadow: 1px 1px 1px $primary;
          // text-shadow: 0px 0px 1.5px rgba($primary, 0.95);
          // text-shadow: 0px 1px 4px rgba($primary, 0.5);
          text-shadow: 0px 1px 4px rgba(lighten($primary, 5%), 0.5);

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .text-addition {
          opacity: 0.4;
        }
      }

      .dashboard-panel-base-black-iframe {
        border: 0;
      }

      .dashboard-panel-base-black-shine {
        margin: 1px 0 0 1px;
        background: url("./assets/img/dashboard-panel-shine@2x.png") no-repeat top center;
        background-size: calc(252px / 2);

        .dashboard-panel-base-black-shine-line {
          height: 1px;
          left: -15px;
          background: url("./assets/img/dashboard-panel-shine-line@2x.png") no-repeat top center;
          background-size: calc(258px / 2);
        }
      }
    }
  }

  .is-on-fire.dashboard-panel .text,
  .is-on-fire.dashboard-panel .text-addition {
    color: white !important;
    text-shadow: 1px 1px 3px rgba(black, 0.3) !important;
    opacity: 1 !important;
  }

  //--------------------------------------------------------
  // FLAMES
  //--------------------------------------------------------

  .dashboard-panel-flame-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: rem-calc(250);
    // border: 1px dashed red;
    overflow: hidden;
    opacity: 0.8;
  }

  .dashboard-panel-flame {
    position: absolute;
    top: 74%;
    transform: scaleX(1.6);
  }

  //--------------------------------------------------------
  // MARQUEE? CHAT GPT
  //--------------------------------------------------------

  .dynamic-marquee-container {
    height: 25px;
    // border: 1px solid yellow;
    margin: -3px 5px 0 5px;
  }

  .text-marquee {
    padding: 6px 0 4px 0;
    font-size: 18px !important;
  }
</style>
